<script lang="ts" setup>
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import { isMobile } from '@consumer/utils/breakpoints'
import { trackClick, trackEvent, trackModal } from '@shared/tracking'
import { whenever } from '@vueuse/core'
import { fadeInOut, modalDropdown } from '@consumer/utils/transitions'

defineOptions({
  inheritAttrs: false,
})

withDefaults(defineProps<{
  name: string
  open: boolean
  rounded?: boolean
  appear?: boolean
  persistent?: boolean // If true, modal cannot be dismissed by clicking outside or hitting ESC
  fullscreenMobile?: boolean
  fullscreen?: boolean
  hideCloseButton?: boolean
}>(), { persistent: false,fullscreenMobile: false,fullscreen: false, })

const emit = defineEmits(['close', 'remove'])

const closeButtonEl = ref<HTMLButtonElement>()

const onClickClose = (e: Event) => {
  trackEvent('click', closeButtonEl.value)
  emit('close')
}

const onClickOutside = () => {
  trackEvent('click', `close-modal-outside-${__props.name}`)
  emit('close')
}

const slots = useSlots()

const isMobileFullscreen = computed(() => isMobile.value && __props.fullscreenMobile)

whenever(() => __props.open, () => trackModal(__props.name))
</script>

<template>
  <TransitionRoot as="template" :show="open" :appear="appear" @afterLeave="emit('remove')">
    <Dialog
      as="div"
      :class="[
        'g-dialog fixed inset-0 w-full flex justify-center items-start z-1000',
        '<lg:p-2 lg:p-4 lg:pt-96px',
        {
          '!p-0': fullscreenMobile ,
          '!lg:p-0': fullscreen
        }
      ]"
      :initialFocus="closeButtonEl"
      :static="true"
      @close="persistent ? null : onClickOutside()"
    >
      <TransitionChild as="template" :appear="appear" v-bind="fadeInOut">
        <div class="fixed inset-0 bg-overlay bg-opacity-40 transition-opacity"/>
      </TransitionChild>
      <TransitionChild as="template" :appear="appear" v-bind="modalDropdown">
        <DialogPanel
          as="div"
          :class="[
            'relative max-h-full mx-auto',
            'bg-white border-solid border-1px border-grey-border rounded-lg',
            'flex flex-col justify-evenly',
            'g-dialog-panel',
            isMobileFullscreen ? 'fixed inset-0 max-w-full h-full !border-0' : 'max-w-440px',
            fullscreen ? 'fixed inset-0 lg:max-w-full h-full rounded-none' : 'lg:max-w-screen-lg'
          ]"
          v-bind="$attrs"
          aria-hidden="false"
          aria-modal="true"
          @click="trackClick"
        >
          <div v-if="!hideCloseButton" class="g-dialog-close absolute top-7 right-6 z-105">
            <button
              id="close-modal-button"
              ref="closeButtonEl"
              :name="`close-modal-icon-button-${name}`"
              class="outline-none"
              @click="onClickClose"
            >
              <GIcon
                name="close"
                size="13px"
                color="grey-500"
                class="cursor-pointer !stroke-1 hover:fill-primary-light hover:stroke-primary-light"
                aria-hidden="true"
              />
            </button>
          </div>
          <DialogTitle
            v-if="slots.header"
            :class="[
              'font-semibold text-lg p-6',
              'border-b-1 border-solid border-b-grey-300 pb-4',
              'g-dialog-title'
            ]"
          >
            <slot name="header"/>
          </DialogTitle>

          <div class="flex-auto flex flex-col overflow-y-auto g-dialog-content">
            <div class="h-full p-6 g-dialog-content-wrapper">
              <slot/>
            </div>
          </div>
          <slot name="actions"/>
        </DialogPanel>
      </TransitionChild>
      <button class="opacity-0 absolute bottom-0"/>
    </Dialog>
  </TransitionRoot>
</template>

<style lang="scss">
.g-dialog {
  .g-dialog-panel {
    box-shadow: 0 4px 20px rgb(0 0 0 / 15%);
  }

  .g-button-large:not(.g-button-no-label) {
    width: 100%;
  }
}
</style>
