// Public: Clamp number between two values.
export function clamp (num: number, min: number, max: number) {
  return Math.min(Math.max(num, min), max)
}

// Public: Converts a number in megabytes to bytes.
export function mbToBytes (sizeInMb: number) {
  return sizeInMb * 1024 * 1024
}

// Converts a file size in bytes into human readable (B, kB, MB, GB, or TB).
export function bytesToHuman (sizeInBytes: number, decimals = 2) {
  const symbols = ['kB', 'MB', 'GB', 'TB']
  let currentValue = sizeInBytes
  let currentSymbol = 'B'
  symbols.every((symbol: string) => {
    const floatValue = currentValue / 1024
    if (floatValue > 1) {
      currentValue = floatValue
      currentSymbol = symbol
      return true
    }
    return false
  })
  return `${Number(currentValue.toFixed(decimals))} ${currentSymbol}`
}

// Public: Formats a number, adding 3-digit separators as needed.
export function formatNumber (num: number) {
  return new Intl.NumberFormat('en-US').format(num)
}

// Public: Formats a dollar amount, stripping away insignificant decimals.
export function formatCurrency (amount?: number | string) {
  const currency = exactCurrency(amount)
  return removeDecimals(currency) || currency
}

// Public: Formats a dollar amount, including all decimals.
export function exactCurrency (amount?: number | string, options?: { local?: boolean }) {
  return new Intl.NumberFormat(options?.local ? navigator.language : 'en-US', {
    style: 'currency',
    currency: 'USD',
  })
    .format(Number(amount ?? 0))
}

// Public: Removes non-significant decimals from a number.
//
// NOTE: Necessary because the backend always appends ".0" to whole numbers.
export function removeDecimals (value?: number | string): string | undefined {
  // Remove any character after a dot, while preserving initial zeros.
  return String(value || '').replace(/\.0*$/, '') || undefined
}
