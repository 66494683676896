<script lang="ts" setup>
withDefaults(defineProps<{
  name: string
  cancelText?: string
  confirmText?: string
  danger?: boolean
}>(), { cancelText: 'Cancel',confirmText: 'Confirm', })

const emit = defineEmits(['cancel', 'confirm'])
const isOpen = ref(false)

onMounted(() => {
  isOpen.value = true
})

const cancel = () => {
  isOpen.value = false
  emit('cancel')
}

const confirm = () => {
  isOpen.value = false
  emit('confirm')
}
</script>

<template>
  <GModal :name="name" :open="isOpen" class="w-md flex flex-col g-alert-modal" @close="cancel">
    <template #header>
      <span v-if="$slots.header" class="text-navy">
        <slot name="header"/>
      </span>
    </template>

    <div class="flex-auto flex flex-col">
      <slot/>
    </div>

    <template #actions>
      <div class="mx-6 mb-6 flex gap-4">
        <GButton
          v-bind="danger ? { secondary: true } : { primary: true, danger: true }"
          size="large"
          class="!w-full"
          :name="`close-alert-modal-button-${name}`"
          @click.prevent="cancel"
        >
          <slot name="cancelButtonLabel">{{ cancelText }}</slot>
        </GButton>
        <GButton
          primary
          :danger="danger"
          size="large"
          class="!w-full"
          :name="`close-alert-modal-button-${name}`"
          @click.prevent="confirm"
        >
          <slot name="confirmButtonLabel">{{ confirmText }}</slot>
        </GButton>
      </div>
    </template>
  </GModal>
</template>

<style lang="scss">
.g-alert-modal {
  .g-dialog-content-wrapper {
    @apply mb-0;
  }
}
</style>
