import { captureException } from '@shared/sentry'
import { isProduction } from '@shared/environment'

// Public: Tracks a non-fatal error that could signal an availability, data, or
// implementation problem, so we want to log an error on local environments,
// but only report it to Sentry in production.
export function notifyError (error: any, captureContext?: Parameters<typeof captureException>[1]) {
  if (import.meta.env.VITEST)
    return

  captureException(error, captureContext)

  if (!isProduction)
    console.error(error, captureContext)
}
