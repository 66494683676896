export const simple = {
  enter: 'transition-opacity duration-75',
  enterFrom: 'opacity-0',
  enterTo: 'opacity-100',
  leave: 'transition-opacity duration-150',
  leaveFrom: 'opacity-100',
  leaveTo: 'opacity-0',
}

export const spinIn = {
  enter: 'transform transition duration-900',
  enterFrom: 'opacity-0 rotate-[-120deg] scale-50',
  enterTo: 'opacity-100 rotate-0 scale-100',
  leave: 'transform duration-500 transition ease-in-out',
  leaveFrom: 'opacity-100 rotate-0 scale-100',
  leaveTo: 'opacity-0 scale-95',
}

export const dropdown = {
  enter: 'transition ease-out duration-100',
  enterFrom: 'transform opacity-0 scale-95',
  enterTo: 'transform opacity-100 scale-100',
  leave: 'transition-all ease-in duration-75',
  leaveFrom: 'transform opacity-100 scale-100',
  leaveTo: 'transform opacity-0 scale-95',
}

export const commandPalette = {
  enter: 'ease-out duration-500',
  enterFrom: 'opacity-0 scale-95',
  enterTo: 'opacity-100 scale-100',
  leave: 'ease-in duration-500',
  leaveFrom: 'opacity-100 scale-100',
  leaveTo: 'opacity-0 scale-95',
}

export const slideOver = {
  enter: 'transform transition-all ease-in-out duration-500',
  enterFrom: 'translate-x-full',
  enterTo: 'translate-x-0',
  leave: 'transform transition-all ease-in-out duration-500',
  leaveFrom: 'translate-x-0',
  leaveTo: 'translate-x-full',
}

export const slideOverFade = {
  enter: 'transform transition-all ease-in-out duration-300 sm:duration-500',
  enterFrom: 'translate-x-full',
  enterTo: 'translate-x-0',
  leave: 'transition-opacity transition-all ease-in-out duration-100',
  leaveFrom: 'translate-x-full opacity-100',
  leaveTo: 'translate-x-0 opacity-0',
}

export const slideDownFade = {
  enter: 'transform transition-all ease-in-out duration-300 sm:duration-500',
  enterFrom: 'translate-y-full',
  enterTo: 'translate-y-0',
  leave: 'transition-opacity transition-all ease-in-out duration-100',
  leaveFrom: 'translate-y-full opacity-100',
  leaveTo: 'translate-y-0 opacity-0',
}

export const flyoutFromTop = {
  enter: 'transition ease-out duration-200',
  enterFrom: 'opacity-0 -translate-y-1',
  enterTo: 'opacity-100 translate-y-0',
  leave: 'transition ease-in duration-150',
  leaveFrom: 'opacity-100 translate-y-0',
  leaveTo: 'opacity-0 -translate-y-1',
}

export const fadeInOut = {
  enter: 'transition-opacity ease-linear duration-300',
  enterFrom: 'opacity-0',
  enterTo: 'opacity-100',
  leave: 'transition-opacity ease-linear duration-300',
  leaveFrom: 'opacity-100',
  leaveTo: 'opacity-0',
}

export const modalDropdown = {
  enter: 'ease-in-out duration-300 transform',
  enterFrom: 'opacity-0 -translate-y-full sm:scale-95',
  enterTo: 'opacity-100 translate-y-0 sm:scale-100',
  leave: 'ease-in-out duration-200 transform',
  leaveFrom: 'opacity-100 translate-y-0 sm:scale-100',
  leaveTo: 'opacity-0 -translate-y-full sm:scale-95',
}
