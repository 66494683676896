<script lang="ts" setup>
withDefaults(defineProps<{
  name: string
  danger?: boolean
}>(), {  })

const emit = defineEmits(['close'])
const isOpen = ref(false)

onMounted(() => {
  isOpen.value = true
})

const close = () => {
  isOpen.value = false
  emit('close')
}
</script>

<template>
  <GModal :name="name" :open="isOpen" class="w-md flex flex-col g-alert-modal" @close="close">
    <template #header>
      <span v-if="$slots.header" :class="[danger ? 'text-red-base' : 'text-navy']">
        <slot name="header"/>
      </span>
    </template>

    <div class="flex-auto flex flex-col">
      <slot/>
    </div>

    <template #actions>
      <div class="mx-6 mb-6">
        <GButton
          primary
          size="large"
          class="!w-full"
          :name="`close-alert-modal-button-${name}`"
          @click.prevent="close"
        >
          <slot name="buttonLabel">Close</slot>
        </GButton>
      </div>
    </template>
  </GModal>
</template>

<style lang="scss">
.g-alert-modal {
  .g-dialog-content-wrapper {
    @apply mb-0;
  }
}
</style>
